// reactstrap components
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getInvoice } from "services/Finance/Payment/PaymentService";
import { resumeCards } from "services/Report/Dashboard";

const Header = () => {
  const [resume, setResume] = useState({
    "qtdProposal": "0",
    "qtdSimulation": "0",
    "qtdUniqueSimulation": "0"
  });
  const [resumePayment, setResumePayment] = useState({})

  const getResumeCards = async() => {
    resumeCards().then((res) => {
      setResume(res.data.resultSet);
    });
  }

  const getResultInvoice = async() => {
    getInvoice().then((res) => {
      setResumePayment(res.data.resultSet);
      console.log(resumePayment);
    })
  }

  useEffect(() => {
    getResumeCards();
    getResultInvoice();
  }, [])




  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Simulações do dia
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                        <span className="badge badge-info">ÚNICAS: {resume.qtdUniqueSimulation}</span> 
                        <span className="badge badge-warning ml-2">TOTAL: {resume.qtdSimulation}</span>
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Propostas do dia
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {resume.qtdProposal}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-file-invoice-dollar"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Tempo médio digitação do dia
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {resume.timeAvg}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i class="fas fa-hourglass-half"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          STATUS DA CONTA
                        </CardTitle>
                        {resumePayment.status !== 'paid' ? (
                          <a 
                          className="btn btn-warning" href={resumePayment.invoiceUrl} 
                          target="_blank" 
                          title="Clique aqui para realizar o pagamento da fatura">
                            <i class="fas fa-file-invoice-dollar"></i> PAGAR MENSALIDADE
                          </a>
                        ) : (
                          <span className="badge badge-success"><h4 className="p-0 m-0">VALIDADE: {dayjs(resumePayment.dateExpiration).format('DD/MM/YYYY')}</h4></span>
                        )}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i class="fas fa-star"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
