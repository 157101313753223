import { useEffect, useState } from "react";
import { listPlanService } from "services/Promoter/License";
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
  Alert,
  CardTitle,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { moneyMask } from "utils/mask";
import { calculatePlanService } from "services/Promoter/License";
import Swal from "sweetalert2";
import { applyPlanService } from "services/Promoter/License";

const PlanComponent = ({ promoterId = null }) => {
  const [plans, setPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState({
    hasSetup: 1,
    qtdUsers: 0,
    discountAmount: 0,
    discountSetup: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [planCalculate, setPlanCalculate] = useState({});
  const [errors, setErrors] = useState([]);

  const handleListPlan = () => {
    listPlanService(1)
      .then(({ data }) => {
        setPlans(data.resultSet);
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleCalculate = () => {
    Swal.fire({
      title: "Calculando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    calculatePlanService({
      productId: planSelected.id,
      discountAmount: planSelected.discountAmount,
      discountSetup: planSelected.discountSetup,
      hasSetup: planSelected.hasSetup,
      promoterId: promoterId,
      qtdUsers: planSelected.qtdUsers,
    })
      .then(({ data }) => {
        Swal.close();
        setPlanCalculate(data.resultSet.plan);
        setErrors(data.resultSet.errors);
      })
      .catch((err) => {
        const code = err.response.data.code;
        const message = err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleApplyPlan = () => {
    if (planSelected.hasSetup <= 0 && !planCalculate?.whyExemptSetup) {
      Swal.fire({
        icon: "error",
        title: "Ops",
        text: "Informar motivo de isenção do SETUP",
      });
      return;
    }

    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja aplicar este plano?",
      showCancelButton: true,
      cancelButtonText: "Sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Salvando plano",
        text: "Por favor, aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      applyPlanService({
        selected: planCalculate,
        calcule: {
          productId: planSelected.id,
          discountAmount: planSelected.discountAmount,
          discountSetup: planSelected.discountSetup,
          hasSetup: planSelected.hasSetup,
          promoterId: promoterId,
          qtdUsers: planSelected.qtdUsers,
        },
      })
        .then(() => {
          setOpenModal(false);
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Plano aplicado com sucesso, agora só emitir a fatura para o cliente! boas vendas!",
          });
        })
        .catch((err) => {
          const code = err.response.data.code;
          const message = err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleListPlan();
  }, []);

  useEffect(() => {
    setPlanCalculate({});
  }, [openModal]);

  return (
    <>
      <div>
        <h3>Planos</h3>
        {plans.length > 0 && (
          <Row>
            {plans.map((register) => (
              <Col md="4" className="d-flex align-content-stretch">
                <Card className="shadow mb-3 w-100">
                  <CardHeader>
                    <CardTitle className="m-0">{register.name}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Alert color="primary">{register.description}</Alert>
                    <p className="m-0">
                      Mensal:{" "}
                      <span className="font-weight-bold">
                        R$ {moneyMask(register.valueAmount)}
                      </span>
                    </p>
                    <p className="m-0">
                      Setup:{" "}
                      <span className="font-weight-bold">
                        R$ {moneyMask(register.valueSetup)}
                      </span>
                    </p>
                    <p className="m-0">
                      Ativação:{" "}
                      <span className="font-weight-bold">
                        R${" "}
                        {moneyMask(
                          Number(register.valueAmount) +
                            Number(register.valueSetup)
                        )}
                      </span>
                    </p>
                    <p className="m-0">
                      Usuários:{" "}
                      <span className="font-weight-bold">{register.qtd}</span>
                    </p>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="success"
                      className="w-100"
                      onClick={() => {
                        setPlanSelected(register);
                        setOpenModal(true);
                      }}
                    >
                      Selecionar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>

      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        size="lg"
      >
        <ModalHeader toggle={() => setOpenModal(false)}>
          <h2>Calcular plano</h2>
        </ModalHeader>
        <ModalBody>
          <h3>Plano</h3>
          <Card className="shadow mb-4">
            <CardBody>
              <Row>
                <Col md="3">
                  <p className="m-0">Mensal:</p>
                  <p className="m-0">
                    <span className="font-weight-bold">
                      R$ {moneyMask(planSelected.valueAmount)}
                    </span>
                  </p>
                </Col>
                <Col md="3">
                  <p className="m-0">Setup:</p>
                  <p className="m-0">
                    <span className="font-weight-bold">
                      R$ {moneyMask(planSelected.valueSetup)}
                    </span>
                  </p>
                </Col>
                <Col md="3">
                  <p className="m-0">Ativação:</p>
                  <p className="m-0">
                    <span className="font-weight-bold">
                      R${" "}
                      {moneyMask(
                        Number(planSelected.valueAmount) +
                          Number(planSelected.valueSetup)
                      )}
                    </span>
                  </p>
                </Col>
                <Col md="3">
                  <p className="m-0">Usuários:</p>
                  <p className="m-0">
                    <span className="font-weight-bold">{planSelected.qtd}</span>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <h3>Configurar</h3>
          <Card className="shadow mb-4">
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Utilizar setup</Label>
                    <select
                      className="form-control form-control-sm"
                      value={planSelected.hasSetup}
                      onChange={(ev) => {
                        setPlanSelected({
                          ...planSelected,
                          hasSetup: Number(ev.target.value),
                        });
                        setPlanCalculate({});
                      }}
                    >
                      <option value="">SELECIONE...</option>
                      <option value={1}>SIM</option>
                      <option value={0}>Não</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Qtd. Usuários</Label>
                    <Input
                      type="number"
                      size="sm"
                      value={planSelected.qtdUsers}
                      onChange={(ev) => {
                        setPlanSelected({
                          ...planSelected,
                          qtdUsers: Number(ev.target.value),
                        });
                        setPlanCalculate({});
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Desconto mensalidade</Label>
                    <Input
                      type="number"
                      size="sm"
                      value={planSelected.discountAmount}
                      onChange={(ev) => {
                        setPlanSelected({
                          ...planSelected,
                          discountAmount: Number(ev.target.value),
                        });
                        setPlanCalculate({});
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Desconto setup</Label>
                    <Input
                      type="number"
                      size="sm"
                      value={planSelected.discountSetup}
                      onChange={(ev) => {
                        setPlanSelected({
                          ...planSelected,
                          discountSetup: Number(ev.target.value),
                        });
                        setPlanCalculate({});
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <Button
                    color="warning"
                    className="w-100 mt-4"
                    onClick={handleCalculate}
                  >
                    Calcular
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {planCalculate.valueMonth > 0 && errors.length <= 0 && (
            <>
              <h3>Plano Calculado</h3>
              <Card className="shadow mb-4">
                <CardBody>
                  <h5>Usuários de sistema - Adicional</h5>
                  <Row>
                    <Col md="3">
                      <p className="m-0">Quantidade:</p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          +{Number(planCalculate.qtdUsers)}
                        </span>
                      </p>
                    </Col>
                    <Col md="4">
                      <p className="m-0">VLR. Unitario usuário:</p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          R${" "}
                          {moneyMask(planCalculate.valueUniqueUser.toFixed(2))}
                        </span>
                      </p>
                    </Col>
                    <Col md="4">
                      <p className="m-0">Vlr. Total Usuário:</p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          R${" "}
                          {moneyMask(planCalculate.totalValueUser.toFixed(2))}
                        </span>
                      </p>
                    </Col>
                  </Row>

                  <h5 className="mt-4">Plano</h5>
                  <Row>
                    <Col md="3">
                      <p className="m-0">Desconto Mensal:</p>
                      <p className="m-0">
                        <span className="font-weight-bold text-success">
                          R${" "}
                          {moneyMask(
                            planCalculate.valueDiscountMonth.toFixed(2)
                          )}
                        </span>
                      </p>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Mensal:</p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          R$ {moneyMask(planCalculate.valueMonth.toFixed(2))}
                        </span>
                      </p>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Desconto Ativação:</p>
                      <p className="m-0">
                        <span className="font-weight-bold text-success">
                          R${" "}
                          {moneyMask(
                            planCalculate.valueDiscountSetup.toFixed(2)
                          )}
                        </span>
                      </p>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Ativação:</p>
                      <p className="m-0">
                        <span className="font-weight-bold">
                          R$ {moneyMask(planCalculate.valueSetup.toFixed(2))}
                        </span>
                      </p>
                    </Col>
                    {planSelected.hasSetup <= 0 && (
                      <Col md="12">
                        <Label className="mt-4">
                          Porque está isentando o setup?
                        </Label>
                        <textarea
                          className="form-control"
                          value={planCalculate.whyExemptSetup}
                          onChange={(ev) => {
                            setPlanCalculate({
                              ...planCalculate,
                              whyExemptSetup: ev.target.value,
                            });
                          }}
                        ></textarea>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </>
          )}

          {errors.length > 0 && (
            <Alert color="danger">
              <h3>Erro</h3>
              {errors.map((register, key) => (
                <p className="m-0" key={key}>
                  - {register}
                </p>
              ))}
            </Alert>
          )}
        </ModalBody>
        {planCalculate.valueMonth > 0 && errors.length <= 0 && (
          <ModalFooter>
            <Button color="success" className="w-100" onClick={handleApplyPlan}>
              APLICAR PLANO
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default PlanComponent;
