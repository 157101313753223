import { useEffect, useState } from "react";
import { listAllProspectUserService } from "services/User/User";
import Swal from "sweetalert2";

const { default: Header } = require("components/Headers/Header");
const { Container, Card, CardHeader, Table } = require("reactstrap");

const ProspectReportView = () => {
  const [list, setList] = useState([]);

  const handleList = () => {
    Swal.fire({
      title: "Carregando",
      message: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listAllProspectUserService()
      .then(({ data }) => {
        setList(data?.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {list.length > 0 && (
          <Card className="shadow">
            <CardHeader><h2>Possíveis clientes - Formulário Prospecção</h2></CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th>DADOS</th>
                  <th>NOME</th>
                  <th>WHATSAPP</th>
                  <th>EMAIL</th>
                  <th>EMPRESA</th>
                  <th>OBSERVAÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {list.map((user, key) => (
                  <tr key={key}>
                    <td>
                      <p className="m-0">
                        <strong>Data Cadastro:</strong> {user.createdAt}
                      </p>
                      <p className="m-0">
                        <strong>Responsável cadastro:</strong> {user.username}
                      </p>
                    </td>
                    <td>
                      <p>{user.name}</p>
                    </td>
                    <td>
                      <p>
                        {user.whatsappNumber}
                        {` `}
                        <a
                          className="btn btn-success btn-sm"
                          href={`https://wa.me/55${user.whatsappNumber.replace(
                            /[^0-9]/g,
                            ""
                          )}`}
                          target="blank"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>{user.email}</p>
                    </td>
                    <td>
                      <p>{user.corporativeName}</p>
                    </td>
                    <td>
                      <p>{user.observation}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ProspectReportView;
