import { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { Container, Row, Col } from "reactstrap";
import { salePerPeriod, statusPerPeriod, simulationGroupPerDay } from "services/Report/Panel";
import ChartPie from "./component/ChartPie";
import ChartLine from "./component/ChartLine";
import ChartBar from "./component/ChartBar";


const Panel = () => {
  const [proposalSalePerPeriod, setProposalSalePerPeriod] = useState([]);
  const [proposalStatusPerPeriod, setProposalStatusPerPeriod] = useState([]);
  const [dataSimulationGroupPerDay, setDataSimulationGroupPerDay] = useState([]);

  const getSalePerPeriod = async (period) => {
    salePerPeriod(period).then((result) => {
      const resultSet = result.data.resultSet;

      setProposalSalePerPeriod({
        labels: resultSet.labels,
        datasets: [
          {
            label: "Venda por período",
            data: resultSet.datasets.data,
            borderColor: '#5e72e4'
          },
        ],
      });
    })
  };

  const getStatusPerPeriod = async (period) => {
    statusPerPeriod(period).then((result) => {
      const resultSet = result.data.resultSet;

      setProposalStatusPerPeriod({
        labels: resultSet.labels,
        datasets: [
          {
            label: "Venda por período",
            data: resultSet.data,
            backgroundColor: [
              '#ef476f',
              '#ffd166',
              '#06d6a0',
              '#118ab2',
              '#073b4c',
              '#277da1',
            ]
          },
        ],
      });
    })
  };

  const getSimulationGroupPerDay = async (period) => {
    simulationGroupPerDay(period).then((result) => {
      const resultSet = result.data.resultSet;

      setDataSimulationGroupPerDay({
        labels: resultSet.labels,
        datasets: resultSet.dataSets,
      });
    })
  };

  useEffect(() => {
    getSalePerPeriod(15);
    getStatusPerPeriod(15);
    getSimulationGroupPerDay(15);
  }, []);

  return (<>
    <Header />
    <Container className="mt--7" fluid>
      <Row>
        <Col xl="12">
          <ChartLine title={`VENDA POR PERÍODO - STATUS PAGO`}
            subtitle={`ULTIMOS 15 DIAS`}
            data={proposalSalePerPeriod}
            background="bg-default mb-4" />
        </Col>
        <Col xl="4">
          <ChartPie title="VENDA POR STATUS" subtitle="ULTIMOS 15 DIAS" data={proposalStatusPerPeriod} />
        </Col>
        <Col xl="8">
          <ChartBar title="SIMULAÇÕES" subtitle="ULTIMOS 15 DIAS" data={dataSimulationGroupPerDay} />
        </Col>
      </Row>
    </Container>
  </>);
}

export default Panel;

