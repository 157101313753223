import Header from "components/Headers/Header";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  Label,
  Table,
} from "reactstrap";
import { indexSimulationOcurrencyErrorService } from "services/Digitation/SimulationService";
import Swal from "sweetalert2";

const ErrorOcurrencyView = () => {
  const [filter, setFilter] = useState({});
  const [result, setResult] = useState([]);

  const handleSubmit = () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      message: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    indexSimulationOcurrencyErrorService(filter)
      .then((res) => {
        Swal.close();
        setResult(res.data.resultSet);
      })
      .catch((error) => {
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        console.log(message);
        Swal.showValidationMessage(`Erro: ${message}`);
      });
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader>Filtro - Erros de simulação</CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-6">
                <Label>Período</Label>
                <div className="input-group">
                  <Input
                    type="date"
                    className="border"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateStart: ev.target.value,
                      });
                    }}
                  />
                  <Input
                    type="date"
                    className="border pl-2"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateEnd: ev.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <Label>Financeira</Label>
                <div className="input-group">
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">SELECIONE...</option>
                    <option value="623">PAN</option>
                    <option value="074">SAFRA</option>
                    <option value="626">C6BANK</option>
                    <option value="149">FACTA</option>
                    <option value="389">MERCANTIL</option>
                    <option value="329">ICRED</option>
                    <option value="329-1">GRANDINO BANK</option>
                    <option value="329-2">SABEMI</option>
                    <option value="243">BANCO MASTER</option>
                    <option value="373">UPP</option>
                    <option value="465">QUER+CREDITO</option>
                    <option value="999">NÃO IDENTIFICADO</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <Label>E-mail da Promotora</Label>
                <div className="input-group">
                  <Input
                    type="email"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        email: ev.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              Filtrar
            </Button>
          </CardFooter>
        </Card>
        <Card className="shadow mt-3">
          <CardHeader>Lista de erros</CardHeader>
          <div className="table-responsive">
            <table className="table align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th>QTD</th>
                  <th>Ocorrência</th>
                  <th>FINANCEIRA</th>
                  <th>ERRO</th>
                </tr>
              </thead>
              <tbody>
                {result.map((register, key) => (
                  <tr key={key}>
                    <td>{register.qtd}</td>
                    <td>
                      <p className="m-0">
                        Primeira <strong>{register.startOcorrency}</strong>
                      </p>
                      <p className="m-0">
                        Última <strong>{register.endOcorrency}</strong>
                      </p>
                    </td>
                    <td>
                      {register.febrabanId} - {register.bank}
                    </td>
                    <td>{register.error}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default ErrorOcurrencyView;
