import api from "services/api"

export const salePerPeriod = async (period) => {
    return api.get(`v1/panel/proposal/sale-per-period/${period}`);
}

export const statusPerPeriod = async (period) => {
    return api.get(`v1/panel/proposal/status-per-period/${period}`);
}

export const simulationGroupPerDay = async (period) => {
    return api.get(`v1/panel/simulation/group-per-day/${period}`);
}