import Panel from "./views/Panel/index.js";
import Index from "./views/Dashboard/Index.js";
import Simulation from "views/Simulation/Index.js";
import PromoterView from "views/Register/Promoter/index.js";
import ListUserIzichatView from "views/izichat/listUser/index.js";
import UserIzichatView from "views/izichat/listUser/details.js";
import ErrorOcurrencyView from "views/Simulation/ErrorOcurrencyView.js";
import AccessBankView from "views/AccessBank/index.js";
import UserView from "views/User/index.js";
import ProspectReportView from "views/Register/Prospect/ProspectReportView.js";

var routes = [
  {
    path: "/index",
    name: "izidev: Clientes",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    show: true,
  },
  {
    path: "/user/izidev",
    name: "Usuário de sistema",
    icon: "ni ni-tv-2 text-primary",
    component: UserView,
    layout: "/admin",
    show: true,
  },
  {
    path: "/user/prospect",
    name: "Possíveis clientes",
    icon: "ni ni-tv-2 text-primary",
    component: ProspectReportView,
    layout: "/admin",
    show: true,
  },
  {
    path: "/simulation",
    name: "izidev: Simulações",
    icon: "ni ni-tv-2 text-primary",
    component: Simulation,
    layout: "/admin",
    show: true,
  },
  {
    path: "/panel",
    name: "izidev: Painel",
    icon: "fas fa-chart-pie text-primary",
    component: Panel,
    layout: "/admin",
    show: true,
  },
  {
    path: "/simulation-error",
    name: "izidev: Erros",
    icon: "fas fa-chart-pie text-primary",
    component: ErrorOcurrencyView,
    layout: "/admin",
    show: true,
  },
  {
    path: "/promoter/:promoterId",
    name: "izidev: Promoter",
    icon: "fas fa-chart-pie text-primary",
    component: PromoterView,
    layout: "/admin",
    show: false,
  },
  {
    path: "/acesso-banco/:promoterId/:febrabanId",
    name: "izidev: Promoter - Acessos Banco",
    icon: "fas fa-chart-pie text-primary",
    component: AccessBankView,
    layout: "/admin",
    show: false,
  },
  {
    path: "/izichat/list",
    name: "izichat: Clientes",
    icon: "fas fa-chart-pie text-primary",
    component: ListUserIzichatView,
    layout: "/admin",
    show: true,
  },
  {
    path: "/izichat/user/:userId",
    name: "izichat: User",
    icon: "fas fa-chart-pie text-primary",
    component: UserIzichatView,
    layout: "/admin",
    show: false,
  },
];
export default routes;
