import api from "services/api";

const ListSimulation = async ({ cpf, email, numberProposal }) => {
  return await api.get("v1/digitation/simulation", {
    params: {
      cpf,
      email,
      numberProposal,
    },
  });
};

const getLogSimulationById = async (id) => {
  return await api.post(`v1/digitation/simulation/log/${id}`);
};

const ListSimulationCsv = async ({
  dateStart = null,
  dateEnd = null,
  document = null,
  status = null,
  error = null,
  numberProposal = null,
}) => {
  return await api.get("v1/digitation/simulation/csv", {
    params: {
      dateStart,
      dateEnd,
      document,
      status,
      page: 0,
      error,
      numberProposal,
    },
  });
};

const ProposalAct = async (simulationId) => {
  return await api.post(`v1/proposal/action-user/${simulationId}`);
};

const CloseProposalAct = async (simulationId) => {
  return await api.put(`v1/proposal/action-user/close/${simulationId}`);
};

const SimulationFgts = async (data) => {
  return await api.post(`v1/digitation/fgts/simulation/manual`, data);
};

const indexSimulationOcurrencyErrorService = async (data) => {
  return await api.get(`v1/simulation/erros`, {
    params: data,
  });
};

export {
  ListSimulation,
  ListSimulationCsv,
  ProposalAct,
  CloseProposalAct,
  SimulationFgts,
  getLogSimulationById,
  indexSimulationOcurrencyErrorService,
};
