import { useEffect, useState } from "react";
import Chart from "chart.js";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Alert,
  Button,
  Badge,
} from "reactstrap";
import Swal from "sweetalert2";

// core components
import { chartOptions, parseOptions } from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { ListSimulation } from "services/Digitation/SimulationService";
import LogSimulation from "./component/register";
import { maskCPF } from "utils/mask";
import { moneyMask } from "utils/mask";

const Simulation = (props) => {
  const [listData, setListData] = useState([]);
  const [modal, setModal] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [email, setEmail] = useState(null);
  const [numberProposal, setNumberProposal] = useState(null);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const handleList = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      message: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await ListSimulation({
      cpf,
      email,
      numberProposal,
    })
      .then((result) => {
        Swal.close();
        setListData(result.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  const openModal = async (id) => {
    await setSimulation(id);
    await setModal(!modal);
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--8" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h1 className="mb-0">SIMULAÇÕES</h1>
                  </div>
                  <div className="col-8 text-right">
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control border"
                        placeholder="E-mail corban"
                        onChange={(ev) => {
                          setEmail(ev.target.value);
                        }}
                      />
                      <input
                        type="text"
                        value={cpf}
                        className="form-control border pl-2"
                        placeholder="CPF Cliente simulação"
                        onChange={(ev) => {
                          setCpf(maskCPF(ev.target.value));
                        }}
                      />
                      <input
                        type="text"
                        value={numberProposal}
                        className="form-control border pl-2"
                        placeholder="Nº da Proposta"
                        onChange={(ev) => {
                          setNumberProposal(ev.target.value);
                        }}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-success"
                          onClick={handleList}
                        >
                          BUSCAR
                        </button>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>DIGITAÇÃO</th>
                          <th>ID</th>
                          <th>SIMULAÇÃO</th>
                          <th>CLIENTE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData.map((data, key) => (
                          <>
                            <tr key={key}>
                              <td style={{ maxWidth: "300px" }}>
                                <p className="m-0">
                                  PROPOSTA:{" "}
                                  <strong>{data.numberProposal}</strong>
                                </p>
                                <p className="m-0">
                                  FEBRABAN: <strong>{data.febrabanId}</strong>
                                </p>
                                <p className="m-0">
                                  ULTIMA ATUALIZAÇÃO:{" "}
                                  <strong>{data.updateAt}</strong>
                                </p>
                                <p className="m-0">
                                  SITUAÇÃO:{" "}
                                  <strong className="badge badge-info">
                                    {data.situation ?? "AGUARDANDO ATUALIZAÇÃO"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  ATIVIDADE:{" "}
                                  <strong className="badge badge-info">
                                    {data.activity ?? "AGUARDANDO ATUALIZAÇÃO"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  ORIGEM:{" "}
                                  <strong className="font-weight-bold">
                                    {data.localeSimulation}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  PRODUTO:{" "}
                                  <strong className="font-weight-bold">
                                    {data.service}
                                  </strong>
                                </p>
                              </td>
                              <td>
                                <p className="m-0">
                                  <strong>{data.id}</strong>
                                </p>
                              </td>
                              <td>
                                <p className="m-0">
                                  Nome Fantasia:
                                  <br />
                                  <strong>{data.namePromoter}</strong>
                                </p>
                                <p className="m-0">
                                  Nome Indicação:
                                  <br />
                                  <strong className="text-warning">
                                    {data.indicationName}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Cod. Indicação:
                                  <br />
                                  <strong className="text-warning">
                                    {data.indicationUuid}
                                  </strong>
                                </p>
                              </td>
                              <td>
                                <p className="m-0">
                                  NOME: <strong>{data.name}</strong>
                                </p>
                                <p className="m-0">
                                  CPF: <strong>{data.cpf}</strong>
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                {data.resultBank.length > 0 && (
                                  <>
                                    <h3 className="mt-3 mb-0">Simulações</h3>
                                    {data.resultBank.map((register, key) => (
                                      <>
                                        <div
                                          className="border rounded p-3"
                                          key={key}
                                        >
                                          <p className="m-0">
                                            Banco:{" "}
                                            <strong>{register.nameBank}</strong>
                                          </p>
                                          <p className="m-0">
                                            VLR. LIBERADO:{" "}
                                            <strong>
                                              R${" "}
                                              {moneyMask(
                                                register.totalCreditLiberty
                                              )}
                                            </strong>
                                          </p>
                                          {register.error && (
                                            <p className="m-0 text-danger">
                                              {register.error}
                                            </p>
                                          )}
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="5" className="bg-secondary">
                                {data.messageReturnService ? (
                                  <p
                                    className="text-danger m-0"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    ERRO:{" "}
                                    <strong>{data.messageReturnService}</strong>
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possui simulações no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <LogSimulation isModal={modal} state={setModal} simulation={simulation} />
    </>
  );
};

export default Simulation;
