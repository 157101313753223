import api from "./../../api";

export const listAllUserIzichatService = async (data) => {
    return await api.get('v1/panel/izichat/users', {params : data})
}
export const getUserIzichatService = async (userId) => {
    return await api.get('v1/panel/izichat/user', {params : {
        userId: userId
    }})
}
export const updateUserIzichatService = async (data) => {
    return await api.put('v1/panel/izichat/user', {
        id: data.id,
        observation: data.observation
    })
}