import axios from 'axios';

const api = axios.create({
  // baseURL: `http://localhost:8000/api/`,
  baseURL: `https://apiadm.izidev.com.br/api/`,
});


api.interceptors.request.use(async (config) => {
    const token = await localStorage.getItem('IZIDEV_AUTH_TOKEN_456');
    if(!token && config.url !== 'v1/token' && config.url !== 'v1/promoter'){ window.location.href = '/auth/login'; }
    if (token) { config.headers.Authorization = token }
    return config
  },
  (error) => {
    return Promise.reject(error)
});

const responseSuccessHandler = (response) => {
  return response
}

const responseErrorHandler = async (error) => {
  const originalConfig = error.config;
  
  if (error.response?.status === 401) {
    try {

      window.location.href = '/auth/login';

      return api(originalConfig)
    } catch (_error) {
      if (_error.response && _error.response.data) {
        return Promise.reject(_error.response.data)
      }

      return Promise.reject(_error)
    }
  }

  return Promise.reject(error)
}

api.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export default api;
