import { Badge, Card, CardFooter, Table } from "reactstrap";

const AccessBankListComponent = ({ accessBank }) => {
  return (
    <Card className="mt-3 shadow">
      <div className="table-responsive">
        <Table className="table-striped">
          <thead>
            <tr>
              <th>BANCO</th>
              <th>LOGIN</th>
              <th>ATIVO</th>
              <th>FGTS</th>
              <th>ROTINA</th>
              <th>SEGURO</th>
              <th>VLR. MIN</th>
              <th>VLR. MAX</th>
            </tr>
          </thead>
          <tbody>
            {accessBank.map((register, key) => (
              <tr key={key}>
                <td>{register.febraban}</td>
                <td>{register.login}</td>
                <td>
                  <Badge color={register.active > 0 ? "success" : "danger"}>
                    {register.active > 0 ? "ATIVO" : "INATIVO"}
                  </Badge>
                </td>
                <td>
                  <Badge color={register.useFgts > 0 ? "success" : "danger"}>
                    {register.useFgts > 0 ? "ATIVO" : "INATIVO"}
                  </Badge>
                </td>
                <td>
                  <Badge
                    color={
                      register.useReSimulationFgts > 0 ? "success" : "danger"
                    }
                  >
                    {register.useReSimulationFgts > 0 ? "ATIVO" : "INATIVO"}
                  </Badge>
                </td>
                <td>
                  <Badge color={register.useSafe > 0 ? "success" : "danger"}>
                    {register.useSafe > 0 ? "ATIVO" : "INATIVO"}
                  </Badge>
                </td>
                <td>{register.minValueLibertyFgts}</td>
                <td>{register.maxValueLibertyFgts}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CardFooter></CardFooter>
    </Card>
  );
};

export default AccessBankListComponent;
