import Header from "components/Headers/Header";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  Table,
  TabPane,
  NavLink,
  Badge,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import { updatePromoter } from "services/Promoter/PromoterService";
import { changePlanService } from "services/Promoter/PromoterService";
import { getPromoterById } from "services/Promoter/PromoterService";
import Swal from "sweetalert2";
import { phoneMask } from "utils/mask";
import AccessBankListComponent from "./Component/AccessBankListComponent";
import { maskCNPJ } from "utils/mask";
import { searchUserPromoterService } from "services/Promoter/PromoterService";
import { listModuleTypeService } from "services/Promoter/License";
import LicensePlanComponent from "./Component/LicensePlanComponent";
import PlanComponent from "./Component/PlanComponent";

const PromoterView = () => {
  const param = useParams();
  const [promoter, setPromoter] = useState(null);
  const [navItem, setNavItem] = useState(1);
  const [filterUser, setFilterUser] = useState({
    email: null,
  });

  const handleShowPromoter = async (promoterId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await getPromoterById(promoterId)
      .then((res) => {
        Swal.close();
        setPromoter(res.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await updatePromoter(promoter)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "SUCESSO",
          text: "Registro alterado com sucesso",
        });
        handleShowPromoter(param.promoterId);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSearchUser = () => {
    searchUserPromoterService({
      ...filterUser,
      promoterId: param.promoterId,
    }).then(({ data }) => {
      console.log(data);
      setPromoter({
        ...promoter,
        users: data?.resultSet ?? [],
      });
    });
  };

  useEffect(() => {
    handleShowPromoter(param.promoterId);
  }, []);

  if (!promoter) {
    return <></>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow mb-3">
          <CardBody>
            <Row>
              <Col md="3">
                <Card className="bg-secondary mt-1 mb-1">
                  <CardBody>
                    <h3 className="m-0">Data vencimento</h3>
                    <h2 className="m-0">{promoter.license.dateExpiration}</h2>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="bg-secondary mt-1 mb-1">
                  <CardBody>
                    <h3 className="m-0">Mensalidade</h3>
                    <h2 className="m-0">R$ {promoter.license.valueMonth}</h2>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="bg-secondary mt-1 mb-1">
                  <CardBody>
                    <h3 className="m-0">Custo Adicional</h3>
                    <h2 className="m-0">
                      R$ {promoter.license.valueImplementation ?? 0}
                    </h2>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="bg-success mt-1 mb-1">
                  <CardBody>
                    <h3 className="m-0 text-white">Total Mensal</h3>
                    <h2 className="m-0 text-white">
                      R${" "}
                      {promoter.license.valueMonth +
                        (promoter.license.valueImplementation ?? 0)}
                    </h2>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="bg-primary mt-1 mb-1">
                  <CardBody>
                    <h3 className="m-0 text-white">Valor Ativação</h3>
                    <h2 className="m-0 text-white">
                      R${" "}
                      {promoter.license?.valueSetup ?? 0}
                    </h2>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="bg-info mt-1 mb-1">
                  <CardBody>
                    <h3 className="m-0 text-white">Plano</h3>
                    <h2 className="m-0 text-white">
                      R${" "}
                      {promoter.license?.plan ?? 0}
                    </h2>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow">
          <CardHeader>Dados da Promotora</CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Nome</Label>
                  <p>{promoter.name}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Razão Social</Label>
                  <p>{promoter.corporativeName}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nome Fantasia</Label>
                  <Input
                    type="text"
                    value={promoter.fantasyName}
                    onChange={(ev) => {
                      setPromoter({
                        ...promoter,
                        fantasyName: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>CNPJ</Label>
                  <p>{maskCNPJ(promoter.document)}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>E-mail</Label>
                  <p>{promoter.email}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Qtd Max. Usuários</Label>
                  <p>{promoter.qtdUsers}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Qtd Usuários Cadastrados</Label>
                  <p>{promoter.qtdUsersCreated}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Banco de Preferência</Label>
                  <p>{promoter.namePreferenceBank}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Qtd. Links Indicação</Label>
                  <p>{promoter.qtdLinkIndication}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Plano</Label>
                  <p>
                    <Badge color="success">{promoter?.license?.plan}</Badge>
                  </p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Dia Vencimento</Label>
                  <p>{promoter?.license?.dayExpire}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Como Conheceu izidev</Label>
                  <p>{promoter?.recommendation ?? "NÃO INFORMADO"}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-4">
          <CardHeader>Informações Master</CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Nome Master</Label>
                  <p>{promoter?.sellerName ?? "NÃO IDENTIFICADO"}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Documento</Label>
                  <p>{promoter?.sellerDocument ?? "NÃO IDENTIFICADO"}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Telefone Contato</Label>
                  <p>{promoter?.sellerPhone ?? "NÃO IDENTIFICADO"}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-4">
          <CardHeader>Informações Contato</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Tel. Contato</Label>
                  <Input
                    type="text"
                    placeholder="(00) 0 0000-0000"
                    value={phoneMask(promoter.phone ?? "")}
                    onChange={(ev) => {
                      setPromoter({ ...promoter, phone: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>WhatsApp</Label>
                  <Input
                    type="text"
                    placeholder="(00) 0 0000-0000"
                    value={phoneMask(promoter.movelPhone ?? "")}
                    onChange={(ev) => {
                      setPromoter({ ...promoter, movelPhone: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>WhatsApp 2</Label>
                  <Input
                    type="text"
                    placeholder="(00) 0 0000-0000"
                    value={phoneMask(promoter.contactPhone ?? "")}
                    onChange={(ev) => {
                      setPromoter({
                        ...promoter,
                        contactPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Tel. Suporte</Label>
                  <Input
                    type="text"
                    placeholder="(00) 0 0000-0000"
                    value={phoneMask(promoter.contactMovelPhone ?? "")}
                    onChange={(ev) => {
                      setPromoter({
                        ...promoter,
                        contactMovelPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Cel. Notificacao</Label>
                  <Input
                    type="text"
                    placeholder="(00) 0 0000-0000"
                    value={phoneMask(promoter.notificationPhone ?? "")}
                    onChange={(ev) => {
                      setPromoter({
                        ...promoter,
                        notificationPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-4">
          <CardHeader>Configurações Estilos</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Dominio <Badge color="warning">sem http ou https</Badge>
                  </Label>
                  <Input
                    type="text"
                    value={promoter.styles.domain ?? ""}
                    onChange={(ev) => {
                      setPromoter({
                        ...promoter,
                        styles: { ...promoter.styles, domain: ev.target.value },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>PIXEL - Código verificação dominio</Label>
                  <Input
                    type="text"
                    value={promoter.styles.pixelDomainVerification ?? ""}
                    onChange={(ev) => {
                      setPromoter({
                        ...promoter,
                        styles: {
                          ...promoter.styles,
                          pixelDomainVerification: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mt-3">
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={navItem === 1 ? "active" : null}
                  onClick={() => {
                    setNavItem(1);
                  }}
                >
                  Análise
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={navItem === 2 ? "active" : null}
                  onClick={() => {
                    setNavItem(2);
                  }}
                >
                  Acessos Banco
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={navItem === 3 ? "active" : null}
                  onClick={() => {
                    setNavItem(3);
                  }}
                >
                  Nível de Acesso
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={navItem === 4 ? "active" : null}
                  onClick={() => {
                    setNavItem(4);
                  }}
                >
                  Usuários de sistema
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={navItem === 5 ? "active" : null}
                  onClick={() => {
                    setNavItem(5);
                  }}
                >
                  Planos
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={navItem} className="p-4 border">
              <TabPane tabId={1}>
                <Card className="mt-3 shadow">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <h3>Simulações</h3>
                        {promoter.qtdSimulationMonth.length > 0 ? (
                          <>
                            <Table className="table-bordered">
                              <thead className="bg-secondary">
                                <tr>
                                  <th>Mês</th>
                                  <th>QTD</th>
                                </tr>
                              </thead>
                              <tbody>
                                {promoter.qtdSimulationMonth.map(
                                  (register, key) => (
                                    <tr key={key}>
                                      <td>{register.month}</td>
                                      <td>{register.qtd}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </>
                        ) : (
                          <Alert color="secondary">Não possuem registros</Alert>
                        )}
                      </Col>
                      <Col md="6">
                        <h3>Propostas</h3>
                        {promoter.qtdProposalMonth.length > 0 ? (
                          <>
                            <Table className="table-bordered">
                              <thead className="bg-secondary">
                                <tr>
                                  <th>Mês</th>
                                  <th>QTD</th>
                                </tr>
                              </thead>
                              <tbody>
                                {promoter.qtdProposalMonth.map(
                                  (register, key) => (
                                    <tr key={key}>
                                      <td>{register.month}</td>
                                      <td>{register.qtd}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </>
                        ) : (
                          <Alert color="secondary">Não possuem registros</Alert>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={2}>
                {promoter.accessBank.length > 0 && (
                  <AccessBankListComponent accessBank={promoter.accessBank} />
                )}
              </TabPane>
              <TabPane tabId={3}>
                <Card className="mt-3 shadow">
                  {promoter.modules.length > 0 ? (
                    <Table className="table-striped">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>NOME</th>
                          <th>URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promoter.modules.map((register, key) => (
                          <tr key={key}>
                            <td>{register.id}</td>
                            <td>{register.name}</td>
                            <td>/admin{register.path}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <CardBody>
                      <Alert color="secondary">
                        Não possui configuração de acesso
                      </Alert>
                    </CardBody>
                  )}
                  <CardFooter></CardFooter>
                </Card>
              </TabPane>
              <TabPane tabId={4}>
                <Card className="shadow">
                  <CardBody>
                    <InputGroup>
                      <Input
                        placeholder="Informe o e-mail do usuário"
                        onChange={(ev) => {
                          setFilterUser({
                            email: ev.target.value,
                          });
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="success" onClick={handleSearchUser}>
                          Buscar
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </CardBody>
                  <CardBody>
                    {promoter?.users.length > 0 && (
                      <>
                        <h4>Usuários cadastrados</h4>
                        <div className="table-responsive">
                          <Table>
                            <thead>
                              <tr>
                                <th>NOME</th>
                                <th>EMAIL</th>
                                <th>TELEFONE</th>
                                <th>NÍVEL</th>
                                <th>STATUS</th>
                                <th>DATA CADASTRO</th>
                                <th>ÚLTIMA ATUALIZAÇÃO</th>
                                <th>DT. ULTIMO ACESSO SISTEMA</th>
                              </tr>
                            </thead>
                            <tbody>
                              {promoter.users.map((register, key) => (
                                <tr>
                                  <td>{register.name}</td>
                                  <td>{register.email}</td>
                                  <td>{register.phone}</td>
                                  <td>{register.level}</td>
                                  <td>
                                    <Badge
                                      color={
                                        register.active > 0
                                          ? "success"
                                          : "warning"
                                      }
                                    >
                                      {register.active > 0
                                        ? "ATIVO"
                                        : "INATIVO"}
                                    </Badge>
                                  </td>
                                  <td>{register.createdAt}</td>
                                  <td>{register.updatedAt}</td>
                                  <td>{register.lastAccess}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    )}

                    {promoter?.users.length <= 0 && (
                      <Alert color="secondary">
                        Não foi identificado registro
                      </Alert>
                    )}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={5}>
                <PlanComponent promoterId={param.promoterId} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>

        <Card className="mt-3 shadow">
          <CardHeader>Observações</CardHeader>
          <CardBody>
            <Label>Informe sua observação</Label>
            <textarea
              className="form-control"
              value={promoter.observation ?? ""}
              onChange={(ev) => {
                setPromoter({ ...promoter, observation: ev.target.value });
              }}
            ></textarea>

            {promoter.observations.length > 0 &&
              promoter.observations.map((register, key) => (
                <div className="border p-3 mt-2 mb-1 rounded">
                  <h4 className="m-0">
                    {register.createdAt} - {register.userName}
                  </h4>
                  <p className="m-0">{register.observation}</p>
                </div>
              ))}
          </CardBody>
        </Card>
        <Card className="shadow mt-3">
          <CardFooter>
            <Button color="success" onClick={handleUpdate}>
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default PromoterView;
