// reactstrap components
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row
} from "reactstrap";
import AuthService from "services/AuthService";
import Swal from "sweetalert2";

const Login = () => {

  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [lock, setLock] = useState(true);
  const history = useHistory();

  useEffect(() => {
    localStorage.clear('IZIDEV_AUTH_TOKEN_456');
  }, [])

  const handleChange = async (event) => {
    if(event.target.name === 'email'){
      setLogin(event.target.value);
    }

    if(event.target.name === 'pass'){
      setPass(event.target.value);
    }

    if(login !== '' && pass !== ''){
      setLock(false);
    }
  }


  const handleSubmit = async () => {
    setLock(true);

    await AuthService({
      email : login,
      password : pass 
    }).then(async (res) => {
      console.log(res.data);

      await localStorage.setItem('IZIDEV_AUTH_TOKEN_456', res.data.resultSet);
      await localStorage.setItem('FIRST_LOGIN_STEP', res.data.step);
      await history.push('/admin/index');

    }).catch((err) => {
      const code = err.response.data.code;
      const message = err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
        // footer: '<a href="">Why do I have this issue?</a>'
      }).finally(() => {
        setLock(false);
      })
    });

  };


  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="pass"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={handleSubmit} disabled={lock}>
                  Realizar login
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            {/* <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Esqueci minha senha?</small>
            </a> */}
          </Col>
          <Col className="text-center" xs="12">
            <a
              className="text-light"
              href="/register"
            >
              <small>Criar uma nova conta</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
