import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.js";


const ChartBar = (props = {
  title: '',
  subtitle: '',
  data: [],
  background: 'bg-white'
}) => {
  if (props.data.length <= 0 || props.data.labels.length <= 0) {
    return (<></>)
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (<>
    <Card className={props.background}>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase ls-1 mb-1">
              {props.subtitle}
            </h6>
            <h2 className="mb-0">{props.title}</h2>
          </div>
          <div className="col">
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          <Bar
            data={props.data}
            options={{
              options: {
                tooltips: {
                  enabled: true,
                  callbacks: {
                    label: function (item, data) {
                      var label = data.datasets[item.datasetIndex].label || "";
                      var yLabel = item.yLabel;
                      var content = "";

                      if (data.datasets.length > 1) {
                        content += label;
                      }

                      content += yLabel;
                      return content;
                    },
                  },
                }
              }
            }}
          />
        </div>
      </CardBody>
    </Card>
  </>)


}

export default ChartBar;