import api from "services/api";

const listAllUsersService = async (data) => {
  return await api.get("v1/user", { params: data });
};

const listAllProspectUserService = async () => {
  return await api.get("v1/prospect-user");
}

export { listAllUsersService, listAllProspectUserService };
