import { useEffect } from 'react';
import {
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';

const AccessBankiCredForm = ({ febrabanId, data, setData }) => {
  useEffect(() => {
    setData({
      ...data,
      febrabanId: febrabanId
    });
  }, []);

  if (!febrabanId || febrabanId !== '329') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>
                Promotora <span className="text-danger">*</span>
              </Label>
              <select
                className="form-control"
                value={data.promoterIntegration}
                onChange={(ev) => {
                  setData({
                    ...data,
                    promoterIntegration: ev.target.value
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>
                <option value="GVN">GVN PROMOTORA</option>
                <option value="AD">AD PROMOTORA</option>
                <option value="ROCHACRED">ROCHA CRED</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value)
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar Tabela de Crédito - FGTS</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                Tabela FGTS <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.tableAmmount}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    tableAmmount: ev.target.value
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>

                <option value="-1">FGTS | Normal</option>
                <option value="1">FGTS | Flex 1</option>
                <option value="2">FGTS | Flex 2</option>
                <option value="3">FGTS | Flex 3</option>
                <option value="4">FGTS | Retenção</option>
                <option value="5">FGTS | Especial 1</option>
                <option value="6">FGTS | Especial 2</option>
                <option value="12">FGTS | Especial 3</option>
                <option value="18">FGTS | Especial 4</option>
                <option value="7">FGTS | AUTO 1</option>
                <option value="8">FGTS | AUTO 2</option>
                <option value="9">FGTS | AUTO 2K</option>
                <option value="10">FGTS | AUTO 6K</option>
                <option value="11">FGTS | AUTO 10K</option>
                <option value="16">FGTS | FLAT 30</option>
                <option value="15">FGTS | AUTO FLAT 50</option>
                <option value="13">FGTS | Light 180</option>
                <option value="20">FGTS | TABELA TOP</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Com seguro <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useSafe}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useSafe: ev.target.value
                  });
                }}
              >
                <option value="0">NÃO</option>
                <option value="1">SIM</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Valor Máximo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </CardBody>
  );
};

export default AccessBankiCredForm;
