// reactstrap components
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { createPromoter } from "services/Promoter/PromoterService";
import Swal from "sweetalert2";
import { brasilApiCep } from "services/cepApi";
import { maskDocument, cepMask, phoneMask } from "utils/mask";

const Register = () => {

  const mainContent = useRef(null);
  const location = useLocation();
  const {pathname} = location;
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [corporativeName, setCorporativeName] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [cep, setCep] = useState(null);
  const [address, setAddress] = useState(null);
  const [district, setDistrict] = useState(null);
  const [city, setCity] = useState(null);
  const [number, setNumber] = useState(null);
  const [complement, setComplement] = useState(null);
  const [contactMovelPhone, setContactMovelPhone] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [uf, setUf] = useState(null);
  const [affiliate, setAffiliate] = useState('');
  const [disableBtn, setDisableBtn] = useState(false); 
  const [valuePopuled, setValuePopuled] = useState(0); 
  
  const history = useHistory();


  const handeChange = async (event) => {
    if(event.target.name === 'name'){
      setName(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'password'){
      setPassword(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'corporativeName'){
      setCorporativeName(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'email'){
      setEmail(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'document'){
      setCnpj(maskDocument(event.target.value));
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'cep'){
      setCep(cepMask(event.target.value));
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'address'){
      setAddress(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'district'){
      setDistrict(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'city'){
      setCity(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'number'){
      setNumber(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'complement'){
      setComplement(event.target.value);
    }

    if(event.target.name === 'contactMovelPhone'){
      setContactMovelPhone(phoneMask(event.target.value));
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'contactEmail'){
      setContactEmail(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'phone'){
      setPhone(phoneMask(event.target.value));
      setValuePopuled(valuePopuled + 1);
    }

    if(event.target.name === 'uf'){
      setUf(event.target.value);
      setValuePopuled(valuePopuled + 1);
    }
  }


  const handleIdentifyAddressUserByCEP = async (event) => {
    setCep(cepMask(event.target.value));
    await brasilApiCep(event.target.value).then((res) => {
      const address = res.data;
      setCep(cepMask(event.target.value));
      setAddress(address.street);
      setDistrict(address.neighborhood);
      setCity(address.city);
      setUf(address.state);
    }).catch(() => {
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `CEP Não encontrado`,
          // footer: '<a href="">Why do I have this issue?</a>'
      });      
      setAddress(null);
      setDistrict(null);
      setCity(null);
      setUf(null);
    })

  }


  const handleSubmit = async (event) => {

    setDisableBtn(true);
    
    event.preventDefault();
    
    await createPromoter({
      name,
      email,
      password,
      corporativeName,
      document : cnpj,
      cep,
      address,
      district,
      city,
      number,
      complement,
      contactMovelPhone,
      contactEmail,
      phone,
      uf,
      affiliate
    })
    .then(async (res) => {
      setDisableBtn(false);
      Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Seu registro foi realizado com sucesso`,
      }).then(() => {
        history.push('/auth');
      })
    })
    .catch((err) => {
      setDisableBtn(false);
      const code =  !err.response ? '00000' : err.response.data.code ;
      const message = !err.response ? 'Ocorreu um erro inesperado' : err.response.data.message;
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
          // footer: '<a href="">Why do I have this issue?</a>'
      })
    });
  }


  useEffect(() => {
    setAffiliate(pathname.replace("/register/", ''));    

    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;


  }, [location, valuePopuled]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col>
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2">
                    <h1>Realize seu cadastro</h1>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={handleSubmit}>
                    {/** NAME */}
                    <FormGroup>
                      <label>Nome</label>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name="name" placeholder="Nome" type="text" value={name} onChange={handeChange} required={true}/>
                      </InputGroup>
                    </FormGroup>
                    {/** CORPORATIVE NAME */}
                    <FormGroup>
                      <label>Razão Social</label>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name="corporativeName" placeholder="Razão Social" type="text" value={corporativeName} onChange={handeChange} required={true}/>
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col>
                        {/** CNPJ */}
                        <FormGroup>
                          <label>CNPJ</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="document" placeholder="CNPJ" type="text" value={cnpj} onChange={handeChange} required={true}/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col>
                        {/** E-MAIL */}
                        <FormGroup>
                          <label>E-mail</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="email" placeholder="E-mail" type="text" value={email} onChange={handeChange} required={true}/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/** FIXO */}
                        <FormGroup>
                          <label>Telefone Fixo</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="phone" placeholder="Telefone Fixo" type="text" value={phone} onChange={handeChange} required={true}/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col>
                        {/** CELULAR */}
                        <FormGroup>
                          <label>Telefone Whatsapp</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="contactMovelPhone" placeholder="Telefone Celular" type="text" value={contactMovelPhone} onChange={handeChange} required={true}/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <h2>Endereço</h2>
                    {/** CEP */}
                    <FormGroup>
                      <label>CEP</label>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name="cep" placeholder="CEP" type="text" value={cep} onBlur={handleIdentifyAddressUserByCEP} onChange={handeChange} required={true}/>
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col>
                        {/** ENDEREÇO */}
                        <FormGroup>
                          <label>Rua</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="address" placeholder="Rua" type="text" value={address} onChange={handeChange}  />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs={4}>
                        {/** NUMERO */}
                        <FormGroup>
                          <label>Numero</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="number" placeholder="Numero" type="text" value={number} onChange={handeChange} required={true} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/** BAIRRO */}
                        <FormGroup>
                          <label>Bairro</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="district" placeholder="Bairro" type="text" value={district} onChange={handeChange}  onBlur={handeChange} />
                          </InputGroup>
                        </FormGroup>
                      </Col><Col>
                        {/** CIDADE */}
                        <FormGroup>
                          <label>Cidade</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="city" placeholder="Cidade" type="text" value={city} onChange={handeChange}  readOnly={true} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col>
                        {/** ESTADO */}
                        <FormGroup>
                          <label>Estado</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="uf" placeholder="Estado" type="text" value={uf} onChange={handeChange}  readOnly={true} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/** COMPLEMENTO */}
                    <FormGroup>
                      <label>Complemento</label>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name="complement" placeholder="Complemento" value={complement} onChange={handeChange}  type="text" />
                      </InputGroup>
                    </FormGroup>
                    <hr />
                    <h2>Dados de acesso</h2>
                    <Row>
                      <Col xs={4}>
                        {/** ENDEREÇO */}
                        <FormGroup>
                          <label>Senha</label>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input name="password" placeholder="Informe sua senha" value={password} onChange={handeChange}  type="password" required={true}/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4" color="primary" type="submit" disabled={disableBtn}>
                        Concluir cadastro
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Register;
