import { useEffect, useState } from "react";
import Chart from "chart.js";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Alert,
  Button,
  Badge,
  CardBody,
  Input,
  Label,
} from "reactstrap";
import Swal from "sweetalert2";

// core components
import { chartOptions, parseOptions } from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { getPromoter } from "services/Promoter/PromoterService";
import { maskDocument, phoneMask } from "utils/mask";
import { aplyDiscontPayment } from "services/Finance/Payment/PaymentService";
import { activeLandingPage } from "services/Promoter/PromoterService";
import { addTestService } from "services/Promoter/PromoterService";
import { newPayment } from "services/Finance/Payment/PaymentService";

const Index = (props) => {
  const [showData, setShowData] = useState(true);
  const [listData, setListData] = useState([]);
  const [modalInfo, setModalInfo] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState(null);
  const [expire, setExpire] = useState(null);
  const [filter, setFilter] = useState(null);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const handleShowData = () => {
    setShowData(!showData);
  };

  const handleList = async (data = { expire: null }) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      message: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await getPromoter({
      ...filter,
      expire: data.expire,
    }).then((result) => {
      Swal.close();
      setListData(result.data.resultSet);
    });
  };

  const aplyDiscont = async (promoterId) => {
    Swal.fire({
      icon: "question",
      title: "Observação",
      text: "Porque está alterando o valor da mensalidade?",
      input: "text",
      showCancelButton: true,
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      if (!confirm.value || confirm.value.replace(/[ ]/g, "") === "") {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Você precisa informar o motivo",
          showCancelButton: false,
        }).then(() => {
          aplyDiscont(promoterId);
        });
      }

      const observation = confirm.value;

      Swal.fire({
        title: "Insira o valor de desconto",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          placeholder: "Exemplo: 799,99",
          id: "value-discont",
        },
        showCancelButton: true,
        confirmButtonText: "Aplicar desconto",
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          return aplyDiscontPayment({
            promoterId: promoterId,
            value: value,
            observation: observation,
          })
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              const message = !error.response
                ? "Ocorreu um erro inesperado"
                : error.response.data.message;
              console.log(message);
              Swal.showValidationMessage(`Erro: ${message}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Desconto aplicado!",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              handleList();
            }
          });
        }
      });
    });
  };

  const activeLP = async (promoterId) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja ativar a LP",
      showCancelButton: true,
      confirmButtonText: "Ativar",
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return activeLandingPage({
          promoterId: promoterId,
        })
          .then((res) => {
            return res.data;
          })
          .catch((error) => {
            const message = !error.response
              ? "Ocorreu um erro inesperado"
              : error.response.data.message;
            console.log(message);
            Swal.showValidationMessage(`Erro: ${message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Landing Page Ativada",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            handleList();
          }
        });
      }
    });
  };

  const addTest = async (promoterId) => {
    Swal.fire({
      icon: "question",
      title: "Observação",
      text: "Porque está adicionando período adicional?",
      input: "text",
      showCancelButton: true,
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      if (!confirm.value || confirm.value.replace(/[ ]/g, "") === "") {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Você precisa informar o motivo",
          showCancelButton: false,
        }).then(() => {
          addTest(promoterId);
        });
      }

      const observation = confirm.value;

      Swal.fire({
        title: "Insira quantos dias deseja adicionar",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          placeholder: "Exemplo: 1",
        },
        showCancelButton: true,
        confirmButtonText: "Adicionar dias",
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          return addTestService({
            promoterId: promoterId,
            day: value,
            observation: observation,
          })
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              const message = !error.response
                ? "Ocorreu um erro inesperado"
                : error.response.data.message;
              console.log(message);
              Swal.showValidationMessage(`Erro: ${message}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Dias de teste adicionados!",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              handleList();
            }
          });
        }
      });
    });
  };

  const handleGenerateNewInvoice = async (id) => {
    Swal.fire({
      title: "Confirmação",
      text: "Tem certeza que deseja gerar um novo Boleto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não, sair",
    }).then((res) => {
      if (!res.isConfirmed) return;

      Swal.fire({
        title: "Gerando novo pagamento",
        text: "Por favor aguarde",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      newPayment(id).then((result) => {
        Swal.fire({
          text: "Sucesso",
          html: `
          <h2>Boleto gerado com sucesso<h2>
          <a class="btn btn-success btn-lg" href="${result.data.invoice}" target="_blank">VISUALIZAR BOLETO</a>
          `,
          icon: "success",
          confirmButtonText: "OK, SAIR",
        });
      });
    });
  };

  useEffect(() => {
    // handleList();
  }, [modalInfo]);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--8" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow mb-4">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-6">
                    <Label>Período</Label>
                    <div className="input-group">
                      <Input
                        type="date"
                        className="border"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            dateStart: ev.target.value,
                          });
                        }}
                      />
                      <Input
                        type="date"
                        className="border pl-2"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            dateEnd: ev.target.value,
                          });
                        }}
                      />
                      <select
                        className="form-control form-control-md border"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            typeDate: ev.target.value,
                          });
                        }}
                      >
                        <option value="createdAt">Data de Cadastro</option>
                        <option value="expiredAt">Data de Vencimento</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label>E-mail</Label>
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control border"
                        placeholder="Informar e-mail do cliente"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            email: ev.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <Label>Status</Label>
                    <div className="input-group">
                      <select
                        className="form-control border"
                        placeholder="Informar e-mail do cliente"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            status: ev.target.value,
                          });
                        }}
                      >
                        <option value="">TODOS</option>
                        <option value={1}>ATIVO</option>
                        <option value={2}>INATIVO</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="bg-secondary pt-2 pb-3 pl-4 border rounded mt-4">
                  <Row className="align-items-center">
                    <div className="col pt-2">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                          await handleList({ expire: -1 });
                        }}
                      >
                        VENCEU
                      </button>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                          await handleList({ expire: 0 });
                        }}
                      >
                        VENCE HOJE
                      </button>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                          await handleList({ expire: 5 });
                        }}
                      >
                        VENCE PROX. 5 DIAS
                      </button>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                          await handleList({ expire: 10 });
                        }}
                      >
                        VENCE PROX. 10 DIAS
                      </button>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={async () => {
                          await handleList({ expire: 120 });
                        }}
                      >
                        NÃO VENCEU
                      </button>
                    </div>
                  </Row>
                </div>
              </CardHeader>
              <CardBody>
                <Button color="success" onClick={handleList}>
                  Filtrar
                </Button>
              </CardBody>
            </Card>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <h1 className="mb-0">CLIENTES</h1>
                  </div>
                  <Col className="text-right">
                    <Button onClick={handleShowData}>
                      EXIBIR / NÃO EXIBIR
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>PROMOTORA</th>
                          <th>CONTATO</th>
                          <th>DATAS</th>
                          <th>SIMULADOR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData.map((data, key) => (
                          <>
                            <tr key={key}>
                              <td>
                                <p className="m-0">
                                  <strong>{data.id}</strong>
                                </p>
                              </td>
                              <td>
                                <span className="badge badge-success">
                                  {data.newUser}
                                </span>
                                <p className="m-0">
                                  Nome Fantasia:{" "}
                                  <strong>
                                    {showData ? data.name : "***********"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  Razão Social:{" "}
                                  <strong>
                                    {showData
                                      ? data.corporativeName
                                      : "***********"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  E-mail:{" "}
                                  <strong>
                                    {showData ? data.email : "***********"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  CNPJ:{" "}
                                  <strong>
                                    {showData ? data.document : "***********"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  VLR. MENSALIDADE:{" "}
                                  <strong>
                                    R${" "}
                                    {showData ? data.valueMonth : "***********"}
                                  </strong>
                                </p>
                                <p className="m-0">
                                  USUÁRIO BUSCA:{" "}
                                  <strong className="badge badge-info">
                                    {data.type}
                                  </strong>
                                </p>
                                <div className="mt-2">
                                  {showData ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {
                                          activeLP(data.id);
                                        }}
                                      >
                                        ATIVAR LANDING PAGE
                                      </button>
                                      <button
                                        className="btn btn-sm btn-info"
                                        onClick={() => {
                                          addTest(data.id);
                                        }}
                                      >
                                        ADICIONAR PERÍODO
                                      </button>

                                      <Button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                          handleGenerateNewInvoice(data.id);
                                        }}
                                      >
                                        <i className="fas fa-file-invoice-dollar" />{" "}
                                        GERAR NOVO BOLETO
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                              <td>
                                <p className="m-0">
                                  FIXO:{" "}
                                  {showData ? (
                                    <strong>
                                      <a
                                        href={
                                          "https://wa.me/55" +
                                          data.phone.replace(/[^0-9]/g, "")
                                        }
                                        target="blank"
                                      >
                                        {phoneMask(data.phone)}
                                      </a>
                                    </strong>
                                  ) : (
                                    "**********"
                                  )}
                                </p>
                                <p className="m-0">
                                  CELULAR:{" "}
                                  {showData ? (
                                    <strong>
                                      <a
                                        href={
                                          "https://wa.me/55" +
                                          data.movelphone.replace(/[^0-9]/g, "")
                                        }
                                        target="blank"
                                      >
                                        {phoneMask(data.movelphone)}
                                      </a>
                                    </strong>
                                  ) : (
                                    "**********"
                                  )}
                                </p>
                              </td>
                              <td>
                                <p className="m-0">
                                  CADASTRO: <strong>{data.createdAt}</strong>
                                </p>
                                <p className="m-0">
                                  EXPIRA: <strong>{data.dateExpiration}</strong>
                                </p>
                                {data.dayToExpire < 0 ? (
                                  <p className="m-0">
                                    TEMPO RESTANTE:{" "}
                                    <strong className="badge badge-danger">
                                      {data.dayToExpire}
                                    </strong>
                                  </p>
                                ) : (
                                  <p className="m-0">
                                    TEMPO RESTANTE:{" "}
                                    <strong className="badge badge-success">
                                      {data.dayToExpire}
                                    </strong>
                                  </p>
                                )}
                                <p className="m-0">
                                  SIMULAÇÕES MÊS:{" "}
                                  <strong>{data.qtdSimulation}</strong>
                                </p>
                                <p className="m-0">
                                  PROPOSTAS MÊS:{" "}
                                  <strong>{data.qtdProposal}</strong>
                                </p>
                              </td>
                              <td>
                                <a
                                  href={`/admin/promoter/${data.id}`}
                                  className="btn btn-success btn-md mt-2"
                                  target="_blank" rel="noreferrer"
                                >
                                  DETALHES
                                </a>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possui simulações no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
