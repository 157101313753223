import Header from "components/Headers/Header";
import { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { listAllUsersService } from "services/User/User";
import Swal from "sweetalert2";

const UserView = () => {
  const [filter, setFilter] = useState({
    emailUser: null,
    emailPromoter: null,
  });
  const [registers, setRegisters] = useState([]);

  const handleSubmit = () => {
    if (
      !filter.emailPromoter &&
      !filter.emailUser &&
      !filter.emailSupervisor &&
      !filter.indicationUuid
    ) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "É preciso informar ao menos 1 filtro",
      });
      return;
    }
    Swal.fire({
      title: "Carregando",
      message: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listAllUsersService(filter)
      .then(({ data }) => {
        setRegisters(data.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });

    console.log(filter);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader>Usuários de Sistema - Filtro</CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>E-mail da Promotora</Label>
                  <Input
                    value={filter.emailPromoter}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        emailPromoter: ev.target.value,
                      });
                    }}
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>E-mail do usuário</Label>
                  <Input
                    value={filter.emailUser}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        emailUser: ev.target.value,
                      });
                    }}
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>E-mail do Supervisor</Label>
                  <Input
                    value={filter.emailSupervisor}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        emailSupervisor: ev.target.value,
                      });
                    }}
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Código de indicação</Label>
                  <Input
                    value={filter.indicationUuid}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        indicationUuid: ev.target.value,
                      });
                    }}
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleSubmit}>
              <i class="fas fa-search"></i> BUSCAR
            </Button>
          </CardFooter>
        </Card>
        {registers.length <= 0 && (
          <Alert color="info" className="mt-4">
            Não retornaram registros para esta consulta
          </Alert>
        )}
        {registers.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>Registro(s)</CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th>PROMOTORA</th>
                  <th>USUÁRIO</th>
                  <th>INDICAÇÃO</th>
                  <th>SUPERVISOR</th>
                </tr>
              </thead>
              <tbody>
                {registers.map((register, key) => (
                  <tr key={key}>
                    <td>
                      <p className="m-0">
                        ID: <strong>{register.promoter.id}</strong>
                      </p>
                      <p className="m-0">
                        Nome: <strong>{register.promoter.name}</strong>
                      </p>
                      <p className="m-0">
                        E-mail: <strong>{register.promoter.email}</strong>
                      </p>
                    </td>
                    <td>
                      <p className="m-0">
                        ID: <strong>{register.id}</strong>
                      </p>
                      <p className="m-0">
                        Nome: <strong>{register.name}</strong>
                      </p>
                      <p className="m-0">
                        E-mail: <strong>{register.email}</strong>
                      </p>
                      <p className="m-0">
                        Status:{" "}
                        <strong>
                          <Badge
                            color={register.active > 0 ? "success" : "danger"}
                          >
                            {register.active > 0 ? "ATIVO" : "INATIVO"}
                          </Badge>
                        </strong>
                      </p>
                      <p className="m-0">
                        Data cadastro: <strong>{register.createdAt}</strong>
                      </p>
                      <p className="m-0">
                        Data Ultima atualização:{" "}
                        <strong>{register.updateAt}</strong>
                      </p>
                    </td>
                    <td>
                      {register?.indicationId > 0 && (
                        <>
                          <p className="m-0">
                            ID: <strong>{register.indication.id}</strong>
                          </p>
                          <p className="m-0">
                            Cód. Indicação:{" "}
                            <strong>{register.indication.uuid}</strong>
                          </p>
                          <p className="m-0">
                            Nome: <strong>{register.indication.name}</strong>
                          </p>
                          <p className="m-0">
                            Status:{" "}
                            <strong>
                              <Badge
                                color={
                                  register.indication.active > 0
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {register.indication.active > 0
                                  ? "ATIVO"
                                  : "INATIVO"}
                              </Badge>
                            </strong>
                          </p>
                        </>
                      )}
                    </td>
                    <td>
                      {register?.supervisorId > 0 && (
                        <>
                          <p className="m-0">
                            ID: <strong>{register.supervisor.id}</strong>
                          </p>
                          <p className="m-0">
                            Nome: <strong>{register.supervisor.name}</strong>
                          </p>
                          <p className="m-0">
                            E-mail: <strong>{register.supervisor.email}</strong>
                          </p>
                          <p className="m-0">
                            Status:{" "}
                            <strong>
                              <Badge
                                color={
                                  register.supervisor.active > 0
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {register.supervisor.active > 0
                                  ? "ATIVO"
                                  : "INATIVO"}
                              </Badge>
                            </strong>
                          </p>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default UserView;
