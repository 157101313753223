import Chart from "chart.js";
import { Line } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Row, Nav, NavItem, NavLink  } from "reactstrap";
import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.js";


const ChartLine = (props = {
  title: '',
  subtitle : '',
  data: [],
  background: 'bg-white'
}) => {
  if (props.data.length <= 0 || props.data.labels.length <= 0) {
    return (<></>)
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (<>
    <Card className={props.background} >
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1">
              {props.subtitle}
            </h6>
            <h2 className="text-white mb-0">{props.title}</h2>
          </div>
          <div className="col">
            {/* <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === 1,
                  })}
                  href="#pablo"
                  onClick={(e) => toggleNavs(e, 1)}
                >
                  <span className="d-none d-md-block">Month</span>
                  <span className="d-md-none">M</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === 2,
                  })}
                  data-toggle="tab"
                  href="#pablo"
                  onClick={(e) => toggleNavs(e, 2)}
                >
                  <span className="d-none d-md-block">Week</span>
                  <span className="d-md-none">W</span>
                </NavLink>
              </NavItem>
            </Nav> */}
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          <Line
            data={props.data}
            options={chartExample1.options}
          />
        </div>
      </CardBody>
    </Card>
  </>)


}

export default ChartLine;