import api from "services/api";
import Swal from "sweetalert2";

/**
 * @param {name : null, email : null, password : null, corporativeName : null, document : null, cep : null, address : null, district : null, city : null, number :null, complement : null, contactMovelPhone :null, contactEmail : null, phone : null, uf : null} data
 * @returns
 */
export const createPromoter = async (
  data = {
    name: null,
    email: null,
    password: null,
    corporativeName: null,
    document: null,
    cep: null,
    address: null,
    district: null,
    city: null,
    number: null,
    complement: null,
    contactMovelPhone: null,
    contactEmail: null,
    phone: null,
    uf: null,
  }
) => {
  return api.post("v1/promoter", data);
};

export const getPromoter = async (data) => {
  return await api.get("v1/promoter", {
    params: data,
  });
};

export const updateSimple = async (data) => {
  return await api.put("v1/promoter/simple", data);
};

export const updateStep = async (step) => {
  return await api.put("v1/promoter/step", { step });
};

export const getStyles = async () => {
  return await api.get("v1/styles");
};

export const updateStyles = async ({
  logo,
  bgColorPrimary,
  bgColorSecondary,
  colorPrimary,
  tagManager,
}) => {
  return await api.put("v1/styles", {
    logo,
    bgColorPrimary,
    bgColorSecondary,
    colorPrimary,
    tagManager,
  });
};

export const activeLandingPage = async (promoterId) => {
  return await api.put("v1/styles/active-lp", promoterId);
};

export const addTestService = async (data) => {
  return await api.put("v1/promoter/test", data);
};

export const getPromoterById = async (promoterId) => {
  return await api.get(`v1/promoter/show`, {
    params: {
      promoterId,
    },
  });
};

export const updatePromoter = async (data) => {
  return await api.put(`v1/promoter`, data);
};

export const changePlanService = async (data) => {
  return await api.post(`v1/promoter/change-plan`, data);
};

export const searchUserPromoterService = async (data) => {
  return await api.get(`v1/promoter/user`, {
    params: data,
  });
};
