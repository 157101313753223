import imgSafra from './../../../assets/img/banks/074.png';
import imgFacta from './../../../assets/img/banks/149.png';
import imgBmg from './../../../assets/img/banks/318.png';
import imgiCred from './../../../assets/img/banks/329.png';
import imgMercantil from './../../../assets/img/banks/389.png';
import imgQueroMais from './../../../assets/img/banks/465.png';
import imgPAN from './../../../assets/img/banks/623.png';
import imgC6 from './../../../assets/img/banks/626.png';
import imgNovoSaque from './../../../assets/img/banks/274.png';
import imgCrefaz from './../../../assets/img/banks/321.png';

export const BankSelectData = (febrabanId) => {
  let bankUsed = null;
  let nameBank = null;

  switch (febrabanId) {
    case '074':
      bankUsed = imgSafra;
      nameBank = 'BANCO SAFRA';
      break;
    case '149':
      bankUsed = imgFacta;
      nameBank = 'FACTA FINANCEIRA';
      break;
    case '318':
      bankUsed = imgBmg;
      nameBank = 'BANCO BMG';
      break;
    case '329':
      bankUsed = imgiCred;
      nameBank = 'iCred';
      break;
    case '389':
      bankUsed = imgMercantil;
      nameBank = 'Banco Mercantil';
      break;
    case '465':
      bankUsed = imgQueroMais;
      nameBank = 'Quero+Crédito';
      break;
    case '623':
      bankUsed = imgPAN;
      nameBank = 'BANCO PAN';
      break;
    case '626':
      bankUsed = imgC6;
      nameBank = 'C6 CONSIG / C6BANK';
      break;
    case '274':
      bankUsed = imgNovoSaque;
      nameBank = 'NOVO SAQUE';
      break;
    case '321':
      bankUsed = imgCrefaz;
      nameBank = 'CREFAZ';
      break;
  }

  return {
    img: bankUsed,
    name: nameBank,
    febrabanId: febrabanId
  };
};
