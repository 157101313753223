import {
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';

const AccessBankC6BankForm = ({ febrabanId, data, setData }) => {
  if (!febrabanId || febrabanId !== '626') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <select value={data.status} className='form-control' 
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked
                });
              }}>
              <option value={1}>ATIVO</option>
              <option value={0}>INATIVO</option>
            </select>
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login C6Consig <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha C6Consig <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value)
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Código Usuário Digitador <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.idBankUser}
                placeholder="Ex: 123456"
                onChange={(ev) => {
                  setData({
                    ...data,
                    idBankUser: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar - FGTS</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                Valor Máximo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </CardBody>
  );
};

export default AccessBankC6BankForm;
