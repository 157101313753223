import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { getUserIzichatService } from "services/izichat/User/UserIzichatService";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { newPaymentIzichat } from "services/Finance/Payment/PaymentService";
import { maskDocument } from "utils/mask";
import { updateUserIzichatService } from "services/izichat/User/UserIzichatService";

const UserIzichatView = () => {
  const [register, setRegister] = useState({});
  const param = useParams();

  const handleGetUser = (userId) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      message: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getUserIzichatService(userId)
      .then((res) => {
        Swal.close();
        setRegister(res.data.resultSet);
      })
      .catch((error) => {
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        Swal.showValidationMessage(`Erro: ${message}`);
      });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await updateUserIzichatService(register)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "SUCESSO",
          text: "Registro alterado com sucesso",
        });
        handleGetUser(param.userId);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGenerateNewInvoice = async () => {
    Swal.fire({
      title: "Confirmação",
      text: "Tem certeza que deseja gerar um novo Boleto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não, sair",
    }).then((res) => {
      if (!res.isConfirmed) return;

      Swal.fire({
        title: "Gerando novo pagamento",
        text: "Por favor aguarde",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      newPaymentIzichat(param.userId).then((result) => {
        Swal.close();

        Swal.fire({
          text: "Sucesso",
          html: `
          <h2>Boleto gerado com sucesso<h2>
          <a class="btn btn-success btn-lg" href="${result.data.invoice}" target="_blank">VISUALIZAR BOLETO</a>
          `,
          icon: "success",
          confirmButtonText: "OK, SAIR",
        });
      });
    });
  };

  useEffect(() => {
    handleGetUser(param.userId);
  }, [param.userId]);

  if (!register.id) {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Alert color="secondary">Por favor aguarde!</Alert>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <Card className="shadow">
          <CardHeader><h3>Dados do cliente</h3></CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Nome</Label>
                  <p className="font-weight-bold">{register.name}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Documento</Label>
                  <p className="font-weight-bold">
                    {maskDocument(register.document)}
                  </p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>E-mail</Label>
                  <p className="font-weight-bold">{register.email}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Telefone</Label>
                  <p className="font-weight-bold">{register.phone}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Data de Cadastro</Label>
                  <p className="font-weight-bold">{register.createdAt}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Vencimento</Label>
                  <p className="font-weight-bold">{register.dateExpire}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Valor Mensalidade</Label>
                  <p className="font-weight-bold">R$ {register.valuePayment}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Dias Restantes</Label>
                  <div>
                    <Badge color={register.diff < 0 ? "danger" : "primary"}>
                      {register.diff < 0 ? `Vencido á ${register.diff} dias` : register.diff}
                    </Badge>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-3">
          <CardHeader><h3>Endereço</h3></CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>CEP</Label>
                  <p className="font-weight-bold">{register?.address?.zipCode ?? ''}</p>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Endereço</Label>
                  <p className="font-weight-bold">{register?.address?.address ?? ''}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nº Endereço</Label>
                  <p className="font-weight-bold">{register?.address?.numberAddress ?? ''}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Bairro</Label>
                  <p className="font-weight-bold">{register?.address?.district ?? ''}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Cidade</Label>
                  <p className="font-weight-bold">{register?.address?.city ?? ''}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Estado</Label>
                  <p className="font-weight-bold">{register?.address?.state ?? ''}</p>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Complemento</Label>
                  <p className="font-weight-bold">{register?.address?.complement ?? ''}</p>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-3">
          <CardHeader><h3>Opções</h3></CardHeader>
          <CardBody>
            <Button
              color="primary"
              onClick={() => {
                handleGenerateNewInvoice();
              }}
            >
              <i class="fas fa-barcode"></i> GERAR FATURA
            </Button>
            {/* {` `}
            <Button color="success"><i class="far fa-surprise"></i> APLICAR DESCONTO IZIDEV R$ 149,99</Button> */}
          </CardBody>
        </Card>

        <Card className="shadow mt-3">
          <CardHeader><h3>Instancias</h3></CardHeader>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data Cadastro</th>
                <th>Tempo Envio</th>
                <th>Data Ultimo Envio</th>
              </tr>
            </thead>
            <tbody>
              {register.instances.map((data, key) => (
                <tr key={key}>
                  <td>
                    <Input size="sm" value={data.name} />
                  </td>
                  <td>
                    <p className="font-weight-bold">{data.createdAt}</p>
                  </td>
                  <td>
                    <p className="font-weight-bold">{data.minuteSendMessage}</p>
                  </td>
                  <td>
                    <p className="font-weight-bold">{data.lastSendMessage}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>

        <Card className="mt-3 shadow">
          <CardHeader><h3>Observações</h3></CardHeader>
          <CardBody>
            <Label>Informe sua observação</Label>
            <textarea
              className="form-control"
              value={register?.observation ?? ""}
              onChange={(ev) => {
                setRegister({ ...register, observation: ev.target.value });
              }}
            ></textarea>

            {register?.observations?.length > 0 &&
              register?.observations?.map((line, key) => (
                <div className="border p-3 mt-2 mb-1 rounded">
                  <h4 className="m-0">
                    {line.createdAt} - {line.userName}
                  </h4>
                  <p className="m-0">{line.observation}</p>
                </div>
              ))}
          </CardBody>
        </Card>
        <Card className="shadow mt-3">
          <CardFooter>
            <Button color="success" onClick={handleUpdate}>
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default UserIzichatView;
