import api from "services/api"

export const listPayments = async () => {
    return await api.get('v1/payment');
}

export const newPayment = async (id) => {
    return await api.post(`v1/payment`, {
        promoterId: id
    });
}

export const newPaymentIzichat = async (id) => {
    return await api.post(`v1/payment/izichat`, {
        userId: id
    });
}


export const getInvoice = async () =>{
    return await api.get('v1/payment/invoice');
}


export const aplyDiscontPayment = async (data) => {
    return await api.post('v1/payment/discont', data);
}