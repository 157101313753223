import api from "services/api";

export const listModuleTypeService = async ({ promoterId }) => {
  return await api.get(`v1/license/modules`, { params: { promoterId } });
};
export const calculeValueMonthService = async (data) => {
  return await api.get(`v1/license/modules/calculate`, {
    params: data,
  });
};
export const savePlanService = async (data) => {
  return await api.post(`v1/license/modules/plan`, data);
};

export const listPlanService = async (typeId) => {
  return await api.get(`v1/product/${typeId}`);
};

export const calculatePlanService = async (data) => {
  return await api.post(`v1/plan/calculate`, data);
};
export const applyPlanService = async (data) => {
  return await api.post(`v1/plan/apply`, data);
};
