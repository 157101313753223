import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, Button, Card, CardFooter, CardHeader, Col, Container, Row } from "reactstrap";
import AccessBankBmgForm from "./formBanks/accessBankBmgForm";
import AccessBankC6BankForm from "./formBanks/accessBankC6BankForm";
import AccessBankCrefazForm from "./formBanks/accessBankCrefazForm";
import AccessBankFactaForm from "./formBanks/accessBankFactaForm";
import AccessBankiCredForm from "./formBanks/accessBankiCredForm";
import AccessBankMercantilForm from "./formBanks/accessBankMercantilForm";
import AccessBankNovoSaqueForm from "./formBanks/accessBankNovoSaqueForm";
import AccessBankPanForm from "./formBanks/accessBankPanForm";
import AccessBankQueroMaisForm from "./formBanks/accessBankQueroMaisForm";
import AccessBankSafraForm from "./formBanks/accessBankSafraForm";
import { BankSelectData } from "./Util/bankSelectData";

const AccessBankView = () => {
  const history = useHistory();
  const [data, setData] = useState({});

  let { febrabanId } = useParams();

  console.log(febrabanId);

  const bank = BankSelectData(febrabanId);

  const handleSubmit = () => {

  }

  return (<>
    <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader>
            <Row>
              <Col>
                <img src={bank.img} alt={bank.name} />
              </Col>
            </Row>
          </CardHeader>
          <AccessBankSafraForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankiCredForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankC6BankForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankFactaForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankQueroMaisForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankMercantilForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankPanForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankBmgForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankNovoSaqueForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankCrefazForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <CardFooter>
            <Button onClick={handleSubmit} color="success">
              <i className="fas fa-save" /> Salvar
            </Button>
            <Button
              color="warning"
              onClick={() => {
                history.push(`/admin/access-bank`);
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </Button>
          </CardFooter>
        </Card>
      </Container>
  </>)
};

export default AccessBankView;