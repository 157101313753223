import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col } from 'reactstrap';
import { getLogSimulationById } from 'services/Digitation/SimulationService';
import Swal from 'sweetalert2';
import { maskDocument } from 'utils/mask';
import JSONPretty from 'react-json-pretty';

const LogSimulation = (props) => {
  const {
    className,
    isModal,
    state,
    handleSteps,
    simulation
  } = props;

  const [modal, setModal] = useState(false);
  const [datalog, setDatalog] = useState([]);

  const getLog = async () => {
    setDatalog([]);
    await getLogSimulationById(simulation).then((res) => {
      setDatalog(res.data.resultSet);
    });
  }

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };

  const closeModal = () => {
    setModal(false);
    state(false);
  };

  useEffect(() => {
    setModal(isModal);
    getLog();
  }, [isModal]);

  return (
    <div>
      <Modal isOpen={modal} toggle={handleToggle} className={className} backdrop={handleSteps ? 'static' : true} size="lg">
        <ModalHeader toggle={closeModal}>LOG</ModalHeader>
        <ModalBody>
          {datalog.map((data, key) => (
            <div className='border border-1 p-4' key={key}>
              <p>{data.createdAt}</p>
              <h1>METODO: {data.method}</h1>
              <h3>MENSAGEM: <strong>{data.message}</strong></h3>
              <JSONPretty id="json-pretty" data={data.data}></JSONPretty>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>SAIR</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default LogSimulation;