import { Alert, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { maskCPF } from 'utils/mask';

const AccessBankNovoSaqueForm = ({ febrabanId, data, setData }) => {
  if (!febrabanId || febrabanId !== '274') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="warning">
        <i className="fas fa-exclamation-triangle" /> Para o funcionamento
        correto da API, os dados de acesso devem ser do tipo{' '}
        <strong>VENDEDOR</strong>. Favor não utilizar login e senha{' '}
        <strong>MASTER</strong>.
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login NovoSaque - Vendedor{' '}
                <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha NovoSaque - Vendedor{' '}
                <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value)
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar Tabela de Crédito</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                Tabela Crédito Pessoal <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.tableAmmount}
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    tableAmmount: ev.target.value
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>

                <option value="25">Tabela Juros Base 25</option>
                <option value="24">Tabela Juros Base 24</option>
                <option value="23">Tabela Juros Base 23</option>
                <option value="22">Tabela Juros Base 22</option>
                <option value="21">Tabela Juros Base 21</option>
                <option value="20">Tabela Juros Base 20</option>
                <option value="19">Tabela Juros Base 19</option>
                <option value="18">Tabela Juros Base 18</option>
                <option value="17">Tabela Juros Base 17</option>
                <option value="16">Tabela Juros Base 16</option>
                <option value="15">Tabela Juros Base 15</option>
                <option value="14">Tabela Juros Base 14</option>
                <option value="13">Tabela Juros Base 13</option>
                <option value="12">Tabela Juros Base 12</option>
                <option value="11">Tabela Juros Base 11</option>
                <option value="10">Tabela Juros Base 10</option>
                <option value="9">Tabela Juros Base 9</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </CardBody>
  );
};

export default AccessBankNovoSaqueForm;
