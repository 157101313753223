import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  Label,
} from "reactstrap";
import { listAllUserIzichatService } from "services/izichat/User/UserIzichatService";
import { moneyMask } from "utils/mask";
import Swal from "sweetalert2";

const ListUserIzichatView = () => {
  const [filter, setFilter] = useState(null);
  const [result, setResult] = useState([]);

  const handleList = () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      message: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listAllUserIzichatService(filter)
      .then((res) => {
        Swal.close();
        setResult(res.data.resultSet);
      })
      .catch((error) => {
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        console.log(message);
        Swal.showValidationMessage(`Erro: ${message}`);
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader>Filtro</CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-6">
                <Label>Período</Label>
                <div className="input-group">
                  <Input
                    type="date"
                    className="border"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateStart: ev.target.value,
                      });
                    }}
                  />
                  <Input
                    type="date"
                    className="border pl-2"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateEnd: ev.target.value,
                      });
                    }}
                  />
                  <select
                    className="form-control form-control-md border"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        typeDate: ev.target.value,
                      });
                    }}
                  >
                    <option value="createdAt">Data de Cadastro</option>
                    <option value="dateExpire">Data Vencimento</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <Label>E-mail</Label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control border"
                    placeholder="Informar e-mail do cliente"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        email: ev.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <Label>Status</Label>
                <div className="input-group">
                  <select className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        status: ev.target.value,
                      });
                    }}>
                    <option value={0}>TODOS</option>
                    <option value={1}>ATIVOS</option>
                    <option value={2}>EXPIRADOS</option>
                  </select>
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleList}>
              Filtrar
            </Button>
          </CardFooter>
        </Card>
        <Card className="shadow mt-3">
          <CardHeader>Lista</CardHeader>
          <div className="table-responsive">
            <table className="table align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th>ID</th>
                  <th>USUARIO</th>
                  <th>CONTATO</th>
                  <th>PERIODOS</th>
                  <th>VALORES</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {result.map((register, key) => (
                  <tr key={key}>
                    <td>{register.id}</td>
                    <td>
                      <Badge color="dark">
                      {register.uuid}
                      </Badge>
                      <p className="m-0">
                        Nome: <strong>{register.name}</strong>
                      </p>
                      <p className="m-0">
                        CPF/CNPJ: <strong>{register.document}</strong>
                      </p>
                      {register.diffCreated <= 30 && (
                        <Badge color="success">CLIENTE NOVO</Badge>
                      )}
                    </td>
                    <td>
                      <p className="m-0">
                        E-mail: <strong>{register.email}</strong>
                      </p>
                      <p className="m-0">
                        Telefone: <strong>{register.phone}</strong>
                      </p>
                    </td>
                    <td>
                      <p className="m-0">
                        Instancias: <strong>{register.instance}</strong>
                      </p>
                      <p className="m-0">
                        Data Cadastro: <strong>{register.createdAt}</strong>
                      </p>
                      <p className="m-0">
                        Vencimento: <strong>{register.dateExpire}</strong>
                      </p>
                      <p className="m-0">
                        Dias Restantes:{" "}
                        <strong>
                          {register.diff < 0 ? (
                            <span className="badge badge-danger">VENCIDO</span>
                          ) : (
                            register.diff
                          )}
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p className="m-0">
                        <strong>R$ {moneyMask(register.valuePayment)}</strong>
                      </p>
                    </td>
                    <td>
                      <a
                        className="btn btn-success"
                        href={`/admin/izichat/user/${register.id}`}
                        target="blank"
                      >
                        DETALHES
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default ListUserIzichatView;
